import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../_models/user';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { PretrainingQuestion } from '@app/_models/pretraining-question';
import { map } from 'rxjs/operators';
import { QuestionResponse } from '@app/_models/question-response';
import { PretrainingStatus } from '@app/_models/pretraining-status';
import { Plugins } from '@capacitor/core';
import { Capacitor } from '@capacitor/core';
import { PackageService } from './package.service';
import { GamificationService } from './gamification.service';
import OneSignal from 'onesignal-cordova-plugin';

const { CapacitorVideoPlayer, Device } = Plugins;

export enum ProfileTutorialState {
	NotStarted = 'notStarted',
	MultipackageHub = 'multipackageHub',
	MultipackageHubViewed = 'multipackageHubViewed',
	InsightsDiscovered = 'insightsDiscovered',
	Development = 'development',
	StrengthAndPotential = 'strengthAndPotential',
	Goals = 'goals',
	Skilltype = 'skilltype',
	Stats = 'stats',
	Completed = 'completed'
}

@Injectable({
	providedIn: 'root'
})
export class PretrainingService {
	private pretrainingQuestions: PretrainingQuestion[];
	private pretrainingStatus: PretrainingStatus;

	private showProfileTutorial: boolean = true;
	private showMultipackageHubTutorial: boolean = true;
	private profileTutorialState: ProfileTutorialState = ProfileTutorialState.NotStarted;

	private currentPretrainingCheckpoint: number = 0;

	constructor(
		private http: HttpClient,
		private apiService: ApiService,
		private gamificationService: GamificationService
	) {}

	loadPretrainingQuestions(packageId: number): Promise<PretrainingQuestion[]> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());

		return this.apiService
			.get<PretrainingQuestion[]>('/questions/pretraining', params)
			.pipe(map((pretrainingQuestions) => (this.pretrainingQuestions = pretrainingQuestions)))
			.toPromise();
	}

	getPretrainingQuestions(): PretrainingQuestion[] {
		return this.pretrainingQuestions;
	}

	setPretrainingQuestionAnswer(pretrainingQuestionIndex: number, answer: number) {
		if (this.pretrainingQuestions[pretrainingQuestionIndex]) {
			console.log('Setting answer for question ' + pretrainingQuestionIndex + ' to ' + answer);
			this.pretrainingQuestions[pretrainingQuestionIndex].answer = answer;
		} else {
			console.log('Index ' + pretrainingQuestionIndex + ' does not exist as a question.');
		}
	}

	savePretrainingQuestionAnswers() {
		const postData = this.pretrainingQuestions.map((question) => ({ pk: question.pk, answer: question.answer }));

		return this.apiService.put<QuestionResponse[]>(`/questions/pretrainingResponses`, postData).pipe(
			map((response) => {
				return response;
			})
		);
	}

	loadPretrainingStatus(packageId: number): Promise<PretrainingStatus> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());

		return this.apiService
			.get<PretrainingStatus>('/pretraining/status', params)
			.pipe(map((pretrainingStatus) => (this.pretrainingStatus = pretrainingStatus)))
			.toPromise();
	}

	savePretrainingCompleted(packageId: number) {
		const postData = { package_id: packageId };

		return this.apiService.put(`/pretraining/pretrainingCompleted`, postData).toPromise();
	}

	savePretrainingCheckpoint(): Promise<number> {
		const postData = { pretraining_checkpoint: this.currentPretrainingCheckpoint };

		return this.apiService.post<any>(`/pretraining/checkpoint`, postData).toPromise();
	}

	incrementCurrentPretrainingCheckpoint() {
		this.currentPretrainingCheckpoint += 1;
	}

	getCurrentPretrainingCheckpoint() {
		return this.currentPretrainingCheckpoint;
	}

	setCurrentPretrainingCheckpoint(num: number) {
		this.currentPretrainingCheckpoint = num;
	}

	savePretrainingCompletedAfterStartDate(packageId: number) {
		const postData = { package_id: packageId };

		return this.apiService.put(`/pretraining/pretrainingCompletedAfterStartDate`, postData).toPromise();
	}

	saveQuestionFeedback(packageId: number, feedback: number) {
		const postData = { package_id: packageId, feedback: feedback };

		return this.apiService.post('/questions/feedback', postData).toPromise();
	}

	displayPushNotificationPopover() {
		const platform = Capacitor.getPlatform();
		if (platform === 'ios' || platform === 'android') {
			return;
		} else {
			return;
		}
	}

	savePackageStartCompleted(packageId: number) {
		const postData = { package_id: packageId };

		return this.apiService.put(`/pretraining/packageStartCompleted`, postData).toPromise();
	}

	getPretrainingStatus(): PretrainingStatus {
		return this.pretrainingStatus;
	}

	isBeforeStartDate(): boolean {
		return !this.pretrainingStatus.package_start_completed;
	}

	getShowProfileTutorial(): boolean {
		return this.showProfileTutorial;
	}

	setShowProfileTutorial(show: boolean) {
		this.showProfileTutorial = show;
	}

	getshowMultipackageHubTutorial(): boolean {
		return this.showMultipackageHubTutorial;
	}

	setShowMultipackageHubTutorial(show: boolean) {
		this.showMultipackageHubTutorial = show;
	}

	setProfileTutorialState(state: ProfileTutorialState) {
		this.profileTutorialState = state;
	}

	getProfileTutorialState(): ProfileTutorialState {
		return this.profileTutorialState;
	}
}
