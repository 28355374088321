import { KeyValueStorage } from '@ionic-enterprise/secure-storage/ngx';
import { Injectable, OnInit } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DataStorageService {
	private ready: Promise<void>;
	private pdf_key: string = 'temporary_pdf';
	private pdf_date_key: string = 'temporary_pdf_date';

	private certificate_key: string = 'temporary_certificate';
	private certificate_date_key: string = 'temporary_certificate_date';

	constructor(private storage: KeyValueStorage) {
		this.createStorage();
	}

	public async readValue(key: string): Promise<string | boolean | undefined> {
		try {
			await this.ready;
			const value = await this.storage.get(key);

			if (value === 'true') {
				return true;
			} else if (value === 'false') {
				return false;
			} else {
				return value; // It's a string
			}
		} catch (error) {
			console.error('readValue', error);
			return undefined;
		}
	}

	public async writeValue(key: string, value: string | boolean): Promise<void> {
		await this.ready;
		await this.storage.set(key, value.toString());
	}

	public async hasValue(key: string): Promise<boolean> {
		await this.ready;
		return this.storage.keys().then((keys) => keys.includes(key));
	}

	public async removeValue(key: string): Promise<void> {
		await this.ready;
		await this.storage.remove(key);
	}

	public async clear(): Promise<void> {
		await this.ready;
		await this.storage.clear();
	}

	/**
	 * Create the storage specifying the encryption key to be used
	 *
	 * @returns Promise
	 */
	private async createStorage() {
		this.ready = this.storage
			.create('NoRealKeyBecauseEncriptionIsNotImportant')
			.then(() => {
				console.log('Storage created');
			})
			.catch((error) => this.handleCreateError(error));
	}

	/**
	 * If the encryption key is invalid we want to clear our storage
	 *
	 * @param error
	 */
	private async handleCreateError(error: Error): Promise<void> {
		if (error?.message?.includes('may be invalid')) {
			try {
				await this.storage.destroyStorage();
				await this.createStorage();
				console.log('Recreated storage as encryption key was invalid');
			} catch (clearError) {
				console.error('handleCreateError.destroyStorage Failure', clearError);
			}
		} else {
			console.error('handleCreateError', error);
		}
	}

	getPdfKey(): string {
		return this.pdf_key;
	}

	getPdfDateKey(): string {
		return this.pdf_date_key;
	}

	getCertificateKey(): string {
		return this.certificate_key;
	}

	getCertificateDateKey(): string {
		return this.certificate_date_key;
	}
}
