import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlayerProgressInfo } from '@app/_models/player-progress-info';
import { ProgressDetails } from '@app/_models/progress-details';
import { RewardedMicroInteraction } from '@app/_models/rewarded-micro-interaction';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { ApiService } from './api.service';
import { TranslationService } from './translation.service';
import { PlayerPointType } from '@app/_models/player-progression-startup';

@Injectable({
	providedIn: 'root'
})
export class RewardedMicroInteractionService {
	private showRewardedMicroInteractionToastSubject: BehaviorSubject<boolean>;
	public showRewardedMicroInteractionToast: Observable<boolean>;

	private showSmallPointAnimationToastSubject: BehaviorSubject<boolean>;
	public showSmallPointAnimationToast: Observable<boolean>;

	public toastTitle: string = '';
	public toastDescription: string = '';
	public pointsGained: number = 0;
	public isToastForAchievement: boolean = false;
	public achievementIconPath: string = '';

	private pointList: RewardedMicroInteraction[];

	private exerciseDurationTimer: number = null;
	private playerPointType: PlayerPointType;

	private isSmallPointAnimationRunning: boolean = false;
	private _isRMIAnimationRunning: boolean = false;

	private rmiName: string;

	constructor(
		private apiService: ApiService,
		private translationService: TranslationService
	) {
		this.showRewardedMicroInteractionToastSubject = new BehaviorSubject<boolean>(null);
		this.showRewardedMicroInteractionToast = this.showRewardedMicroInteractionToastSubject.asObservable();

		this.showSmallPointAnimationToastSubject = new BehaviorSubject<boolean>(null);
		this.showSmallPointAnimationToast = this.showSmallPointAnimationToastSubject.asObservable();
	}

	loadRewardedMicroInteractionPointList(): Promise<RewardedMicroInteraction[]> {
		return this.apiService
			.get<RewardedMicroInteraction[]>('/gamification/point_list')
			.pipe(map((pointList) => (this.pointList = pointList)))
			.toPromise();
	}

	sendRewardedMicroInteraction(RMI_name: string) {
		let params = new HttpParams();
		params = params.set('origin_key', RMI_name);
		const postData = {
			origin_key: RMI_name
		};

		this.apiService
			.post<ProgressDetails>('/gamification/rmi', {}, params)
			.pipe(
				tap((progressDetails) => {
					if (progressDetails.player_progress_update.points != null) {
						this.initializeRMIAndStartIfPossible(
							this.translationService.translate.instant('progressionComponent.bonusPoints'),
							this.pointList.find((rmi) => rmi.name == RMI_name).description,
							progressDetails.point_details.bonus_points,
							false,
							0
						);
					}
				})
			)
			.toPromise();
	}

	initializeRMIAndStartIfPossible(toastTitle: string, toastDescription: string, pointsGained: number, isToastForAchievement: boolean, retryCount: number = 0) {
		if (!this.isRMIAnimationRunning()) {
			this.setIsRMIAnimationRunning(true);

			this.toastTitle = toastTitle;
			this.toastDescription = toastDescription;
			this.pointsGained = pointsGained;
			this.isToastForAchievement = isToastForAchievement;

			this.showRewardedMicroInteractionToastSubject.next(true);
		} else {
			console.log('RMI Animation is already running');
			console.log('Retry count: ', retryCount);
			setTimeout(() => {
				this.initializeRMIAndStartIfPossible(toastTitle, toastDescription, pointsGained, isToastForAchievement, retryCount + 1);
			}, 700);
		}
	}

	showFakeRewardedMicroInteraction(RMI_name: string) {
		this.toastTitle = this.translationService.translate.instant('progressionComponent.bonusPoints');
		this.toastDescription = this.pointList.find((rmi) => rmi.name == RMI_name).description;
		this.pointsGained = this.pointList.find((rmi) => rmi.name == RMI_name).points;
		this.isToastForAchievement = false;
		this.showRewardedMicroInteractionToastSubject.next(true);
	}

	resetShowRewardedMicroInteractionToastSubject() {
		this.showRewardedMicroInteractionToastSubject.next(false);
	}

	presentToastWithSmallPointsAnimation(pointsGained: number) {
		console.log('presentToastWithSmallPointsAnimation, ', pointsGained);
		this.toastTitle = this.getTitleForSmallPointAnimation();
		this.toastDescription = this.getDescriptionForSmallPointAnimation();
		this.pointsGained = pointsGained;
		this.isToastForAchievement = false;

		this.showSmallPointAnimationToastSubject.next(true);
	}

	resetShowSmallPointAnimationToastSubject() {
		this.showSmallPointAnimationToastSubject.next(false);
	}

	getPointList(): RewardedMicroInteraction[] {
		return this.pointList;
	}

	setExerciseStartTime() {
		if (this.exerciseDurationTimer == null) {
			this.exerciseDurationTimer = new Date().getTime();
		}
	}

	meassureExerciseElapsedTime(): boolean {
		let elapsed = new Date().getTime() - this.exerciseDurationTimer;

		if (elapsed / 1000 >= this.getPointList().find((rmi) => rmi.name == 'minimum_exercise_duration').min_time_sec) {
			return true;
		}

		return false;
	}

	resetExerciseStartTimer() {
		this.exerciseDurationTimer = null;
	}

	setPlayerPointType(playerPointType: PlayerPointType) {
		this.playerPointType = playerPointType;
	}

	getPlayerPointType(): PlayerPointType {
		return this.playerPointType;
	}

	setRMIName(rmiName: string) {
		console.log('setRMIName', rmiName);
		this.rmiName = rmiName;
	}

	getTitleForSmallPointAnimation(): string {
		switch (this.playerPointType) {
			case PlayerPointType.microtraining:
				return this.translationService.translate.instant('progressionComponent.trainingCompleted');
			case PlayerPointType.daily_challenge:
				return this.translationService.translate.instant('progressionComponent.dailyChallengeCompleted');
			case PlayerPointType.quiz:
				return this.translationService.translate.instant('progressionComponent.quizCompleted');
			case PlayerPointType.rmi:
				return this.translationService.translate.instant('progressionComponent.bonusPoints');
			case PlayerPointType.rmi_achievement:
				return this.translationService.translate.instant('progressionComponent.achievementUnlocked');
			case PlayerPointType.streak:
				return this.translationService.translate.instant('progressionComponent.bonusPoints');
			case PlayerPointType.duel:
				return this.translationService.translate.instant('progressionComponent.bonusPoints');
			case PlayerPointType.totw:
				return this.translationService.translate.instant('progressionComponent.bonusPoints');
			default:
				console.error('PlayerPointType not found');
				return this.translationService.translate.instant('progressionComponent.bonusPoints');
		}
	}

	getDescriptionForSmallPointAnimation(): string {
		switch (this.playerPointType) {
			case PlayerPointType.microtraining:
				return null;
			case PlayerPointType.daily_challenge:
				return null;
			case PlayerPointType.quiz:
				return null;
			case PlayerPointType.rmi:
				return this.pointList.find((rmi) => rmi.name == this.rmiName).description;
			case PlayerPointType.rmi_achievement:
				return null;
			case PlayerPointType.streak:
				return this.pointList.find((rmi) => rmi.name == 'streak_increase').description;
			case PlayerPointType.duel:
				return this.pointList.find((rmi) => rmi.name == 'duel_completed').description;
			case PlayerPointType.totw:
				return this.pointList.find((rmi) => rmi.name == 'minimum_totw_duration').description;
			default:
				console.error('PlayerPointType not found');
				return null;
		}
	}

	getIsSmallPointAnimationRunning(): boolean {
		return this.isSmallPointAnimationRunning;
	}

	setIsSmallPointAnimationRunning(isRunning: boolean) {
		this.isSmallPointAnimationRunning = isRunning;
	}

	isRMIAnimationRunning(): boolean {
		return this._isRMIAnimationRunning;
	}

	setIsRMIAnimationRunning(isRunning: boolean) {
		this._isRMIAnimationRunning = isRunning;
	}
}
