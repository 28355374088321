import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import { addDays, addHours, addMinutes, getDay, getUnixTime, parseISO, startOfDay, startOfWeek } from 'date-fns';
import OneSignal from 'onesignal-cordova-plugin';
import { ApiService } from './api.service';
import { PackageService } from './package.service';
import { SingleTrainingService } from './single-training.service';
import { TranslationService } from './translation.service';

@Injectable({
	providedIn: 'root'
})
export class PushNotificationService {
	//Monday = 0, Tuesday = 1
	private currentlySelectedDayForCalendarBlocker: number = 0;

	private calendarBlockerOffsetToMondayMorning: number = 0;
	private blocker_notifications_enabled: boolean = false;

	constructor(
		private apiService: ApiService,
		private singleTrainingService: SingleTrainingService,
		private translationService: TranslationService,
		private packageService: PackageService
	) {}

	async initializeOneSignal() {
		// If not native skip initializing of OneSignal
		if (!environment.isNative) {
			console.log('OneSignal not initialized as environment.isNative');
			return;
		}
		// Uncomment to set OneSignal device logging to VERBOSE
		OneSignal.Debug.setLogLevel(6);

		// NOTE: Update the setAppId value below with your OneSignal AppId.
		OneSignal.initialize(environment.oneSignalId);
		OneSignal.Notifications.addEventListener('click', async (e) => {
			let clickData = await e.notification;
			console.log('Notification Clicked : ' + clickData);
		});

		// Prompts the user for notification permissions.
		//    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
		OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
			console.log('Notification permission granted ' + success);
		});

		console.log('OneSignal Subscription', JSON.stringify(await OneSignal.User.pushSubscription));
		console.log(
			'OneSignal ID',
			JSON.stringify(await OneSignal.User.getOnesignalId()),
			'Onesignal External ID',
			JSON.stringify(OneSignal.User.getExternalId()),
			'Tags',
			JSON.stringify(await OneSignal.User.getTags())
		);
	}

	// These functions are async, because they access the OneSignal API
	async setExternalUserId(user_id) {
		if (environment.isNative) {
			await OneSignal.login(user_id);
			await OneSignal.User.addEmail(user_id);
			OneSignal.User.getExternalId().then((externalId) => {
				console.log('Onsignal External ID: ' + externalId);
			});
		}
	}

	getcurrentlySelectedDayForCalendarBlocker(): number {
		return this.currentlySelectedDayForCalendarBlocker;
	}

	//Monday = 0, Tuesday = 1
	setCurrentlySelectedDayForCalendarBlocker(dayAsNumber: number) {
		this.currentlySelectedDayForCalendarBlocker = dayAsNumber;
	}

	setCalendarBlocker(datetime: Date, pushEnabled: boolean) {
		let mondayMorning = startOfWeek(parseISO(this.packageService.getCurrentPackage().start_date), { locale: this.translationService.locale, weekStartsOn: 1 });
		this.calendarBlockerOffsetToMondayMorning = getUnixTime(datetime) - getUnixTime(mondayMorning);

		this.blocker_notifications_enabled = pushEnabled;
		console.log(
			'Calendar Blocker set for Datetime: ' +
				datetime.toString() +
				' - Unix Timestamp in Seconds: ' +
				getUnixTime(datetime) +
				' StartOfWeek: ' +
				mondayMorning.toString() +
				' Offset: ' +
				this.calendarBlockerOffsetToMondayMorning
		);
		console.log('PushNotification is set to: ' + this.blocker_notifications_enabled);
	}

	saveCalendarBlocker(offset?: number, pushEnable?: boolean): Promise<number> {
		console.log('saveCalendarBlocker was called');
		const postData = {
			offset_to_weekly_time_reference: offset || this.calendarBlockerOffsetToMondayMorning,
			calendar_invite_enabled: true,
			blocker_notifications_enabled: pushEnable || this.blocker_notifications_enabled
		};

		return this.apiService.post<number>('/microtraining/blocker', postData).toPromise();
	}

	setWeeklyQuizTimeReference() {
		console.log('The setWeeklyQuizTimeReference Method has been called to set a data tag in onesignal.');

		let trainingCompletedDatetime = this.singleTrainingService.getCurrentWeekStatus().training_completed_date;
		// Calculate the day after the completed Microtraining at 16:30
		let quizUnlockDatetime = addMinutes(addHours(startOfDay(addDays(parseISO(trainingCompletedDatetime), 1)), 16), 30);

		console.log('The microtraining was completed at ' + trainingCompletedDatetime.toString() + ' which leads to a quizUnlockDatetime of ' + quizUnlockDatetime.toString());

		// If calculated quiz unlocked time would be later then Friday, unlock quiz immediately
		if (getDay(quizUnlockDatetime) > 5) {
			console.log(
				'As this is later then Friday, the quiz should be immediately unlocked which means the quizUnlockDatetime is set to the trainingCompletedDatetime of ' +
					trainingCompletedDatetime.toString()
			);
			quizUnlockDatetime = parseISO(trainingCompletedDatetime);
			return true;
		}

		console.log('Weekly Quiz Time Reference set to Datetime: ' + quizUnlockDatetime + ' - Unix Timestamp in Seconds: ' + getUnixTime(quizUnlockDatetime));
		if (environment.isNative) {
			OneSignal.User.addTag('weekly_quiz_reference', getUnixTime(quizUnlockDatetime).toString());
		}
	}
}
