import { Injectable } from '@angular/core';
import { DuelStartupLocation } from '@app/_models/duel-startup-location';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DuelDetail } from '@app/_models/duel-detail';
import { DuelOverview } from '@app/_models/duel-overview';
import { DuelWeekStats } from '@app/_models/duel-week-stats';
import { GeneratedDuelSuggestion } from '@app/_models/generated-duel-suggestion';
import { ProgressDetails } from '@app/_models/progress-details';
import { GamificationService } from './gamification.service';

@Injectable({
	providedIn: 'root'
})
export class DuelService {
	private duelStartupLocation: DuelStartupLocation;

	private generatedDuel: GeneratedDuelSuggestion;

	private duelOverview: DuelOverview;
	private duelWeekStats: DuelWeekStats;
	private duelForDetailsScreen: DuelDetail;
	private duelForSummaryScreen: DuelDetail;

	private wasDuelDetailOpenedForNewlyGeneratedDuel: boolean = false;

	private showDuelStartedToastSubject: BehaviorSubject<boolean>;
	public showDuelStartedToast: Observable<boolean>;

	private startSlotMachineAnimSubject: BehaviorSubject<boolean>;
	public startSlotMachineAnim: Observable<boolean>;

	public isSlotMachineAnimationRunning: boolean = false;
	private resultFromGeneratingDuelHasArrived: boolean = false;

	constructor(
		private apiService: ApiService,
		private gamificationService: GamificationService
	) {
		this.showDuelStartedToastSubject = new BehaviorSubject<boolean>(null);
		this.showDuelStartedToast = this.showDuelStartedToastSubject.asObservable();

		this.startSlotMachineAnimSubject = new BehaviorSubject<boolean>(null);
		this.startSlotMachineAnim = this.startSlotMachineAnimSubject.asObservable();
	}

	loadDuelWeekStats(): Promise<DuelWeekStats> {
		return this.apiService
			.get<DuelWeekStats>('/duel/week_stats')
			.pipe(map((duelWeekStat) => (this.duelWeekStats = duelWeekStat)))
			.toPromise();
	}

	loadDuelOverview(): Promise<DuelOverview> {
		return this.apiService
			.get<DuelOverview>('/duel/player_stats')
			.pipe(map((duelOverview) => (this.duelOverview = duelOverview)))
			.toPromise();
	}

	loadGeneratedDuel(): Promise<GeneratedDuelSuggestion> {
		return this.apiService
			.get<GeneratedDuelSuggestion>('/duel/suggestion')
			.pipe(map((duelDetail) => (this.generatedDuel = duelDetail)))
			.toPromise();
	}

	startGeneratedDuel(): Promise<any> {
		var postData = this.generatedDuel;

		return this.apiService
			.post<DuelDetail>('/duel/suggestion', postData)
			.pipe(
				map((duelDetail) => {
					this.duelForDetailsScreen = duelDetail;
				})
			)
			.toPromise();
	}

	sendDuelInvitationViewed(): Promise<any> {
		//var postData = { duel_id: [...this.duelWeekStats.unseen_duels_this_week] };
		var postData = { duel_ids: this.duelWeekStats.unseen_duels_this_week };

		return this.apiService.post<any>('/duel/noticed_duel_invitation', postData).toPromise();
	}

	sendDuelCompletionViewed(id: number): Promise<any> {
		var postData = { duel_id: this.duelWeekStats.unnoticed_completed_duels[0] };

		this.gamificationService.resetProgressDetails();

		return this.apiService
			.post<ProgressDetails>('/duel/noticed_duel_completion', postData)
			.pipe(
				map((progressDetails) => {
					this.gamificationService.progressDetails = progressDetails;
				})
			)
			.toPromise();
	}

	getDuelstartupLocation(): DuelStartupLocation {
		return this.duelStartupLocation;
	}

	setDuelstartupLocation(location: DuelStartupLocation) {
		this.duelStartupLocation = location;
	}

	getDuelOverview(): DuelOverview {
		return this.duelOverview;
	}

	getDuelWeekStats(): DuelWeekStats {
		return this.duelWeekStats;
	}

	setDuelForDetailsScreen(duel: DuelDetail) {
		this.duelForDetailsScreen = duel;
	}

	getDuelForDetailScreen(): DuelDetail {
		return this.duelForDetailsScreen;
	}

	setDuelForSummaryScreen(duel: DuelDetail) {
		this.duelForSummaryScreen = duel;
	}

	getDuelForSummaryScreen(): DuelDetail {
		return this.duelForSummaryScreen;
	}

	hasDuelBeenInitiated(): boolean {
		return this.duelOverview.initiated_duels.length > 0;
	}

	getGeneratedDuelInformation(): GeneratedDuelSuggestion {
		return this.generatedDuel;
	}

	duelSummaryAndNoInvitesAvailable(): boolean {
		if (this.duelWeekStats.unnoticed_completed_duels.length > 0 && this.duelWeekStats.unseen_duels_this_week.length == 0) {
			return true;
		} else {
			return false;
		}
	}

	triggerShowDuelStartedToast() {
		this.showDuelStartedToastSubject.next(true);
	}

	resetShowDuelStartedToast() {
		this.showDuelStartedToastSubject.next(false);
	}

	triggerStartSlotMachineAnim() {
		this.startSlotMachineAnimSubject.next(true);
	}

	resetStartSlotMachineAnim() {
		this.startSlotMachineAnimSubject.next(false);
	}

	getDuelDetailsByID(id: number): DuelDetail {
		let detail = this.duelOverview.initiated_duels.find((duelDetail) => duelDetail.pk == id);

		if (detail == undefined) {
			detail = this.duelOverview.received_duels.find((duelDetail) => duelDetail.pk == id);
		}

		return detail;
	}

	setResultFromGeneratingDuelHasArrived() {
		this.resultFromGeneratingDuelHasArrived = true;
	}

	resetResultFromGeneratingDuelHasArrived() {
		this.resultFromGeneratingDuelHasArrived = false;
	}

	getResultFromGeneratingDuelHasArrived(): boolean {
		return this.resultFromGeneratingDuelHasArrived;
	}

	getWasDuelDetailOpenedForNewlyGeneratedDuel(): boolean {
		return this.wasDuelDetailOpenedForNewlyGeneratedDuel;
	}

	setWasDuelDetailOpenedForNewlyGeneratedDuel() {
		this.wasDuelDetailOpenedForNewlyGeneratedDuel = true;
	}

	resetWasDuelDetailOpenedForNewlyGeneratedDuel() {
		this.wasDuelDetailOpenedForNewlyGeneratedDuel = false;
	}
}
