import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'home',
		loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'training',
		loadChildren: () => import('./training/training.module').then((m) => m.TrainingPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'training-suggestion',
		loadChildren: () => import('./training/training-suggestion/training-suggestion.module').then((m) => m.TrainingSuggestionPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'profile',
		loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule)
	},
	{
		path: 'self-service-login-screen',
		loadChildren: () => import('./self-service/self-service-login/self-service-login.module').then((m) => m.SelfServiceLoginPageModule)
	},
	{
		path: 'tabs',
		loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'app-welcome-screen',
		loadChildren: () => import('./app-welcome-screen/app-welcome-screen.module').then((m) => m.AppWelcomeScreenPageModule)
	},
	{
		path: 'pretraining-questions-intro',
		loadChildren: () => import('./pretraining/pretraining-questions-intro/pretraining-questions-intro.module').then((m) => m.PretrainingQuestionsIntroPageModule)
	},
	{
		path: 'pretraining-question',
		loadChildren: () => import('./pretraining/pretraining-question/pretraining-question.module').then((m) => m.PretrainingQuestionPageModule)
	},
	{
		path: 'package-intro',
		loadChildren: () => import('./pretraining/package-intro/package-intro.module').then((m) => m.PackageIntroPageModule)
	},
	{
		path: 'request-error',
		loadChildren: () => import('./request-error/request-error.module').then((m) => m.RequestErrorPageModule)
	},
	{
		path: 'generic-goals',
		loadChildren: () => import('./pretraining/generic-goals/generic-goals.module').then((m) => m.GenericGoalsPageModule)
	},
	{
		path: 'calendar-blocker',
		loadChildren: () => import('./pretraining/calendar-blocker/calendar-blocker.module').then((m) => m.CalendarBlockerPageModule)
	},
	{
		path: 'avatar-selection',
		loadChildren: () => import('./pretraining/avatar-selection/avatar-selection.module').then((m) => m.AvatarSelectionPageModule)
	},
	{
		path: 'profile-introduction',
		loadChildren: () => import('./pretraining/profile-introduction/profile-introduction.module').then((m) => m.ProfileIntroductionPageModule)
	},
	{
		path: 'pretraining-completed',
		loadChildren: () => import('./pretraining/pretraining-completed/pretraining-completed.module').then((m) => m.PretrainingCompletedPageModule)
	},
	{
		path: 'pretraining-intro',
		loadChildren: () => import('./pretraining/pretraining-intro/pretraining-intro.module').then((m) => m.PretrainingIntroPageModule)
	},
	{
		path: 'achievement-review',
		loadChildren: () => import('./achievement-review/achievement-review.module').then((m) => m.AchievementReviewPageModule)
	},
	{
		path: 'weekly-quiz',
		loadChildren: () => import('./weekly-quiz/weekly-quiz.module').then((m) => m.WeeklyQuizPageModule)
	},
	{
		path: 'pilot-user-introduction',
		loadChildren: () => import('./pretraining/pilot-user-introduction/pilot-user-introduction.module').then((m) => m.PilotUserIntroductionPageModule)
	},
	{
		path: 'tip-of-the-week',
		loadChildren: () => import('./tip-of-the-week/tip-of-the-week.module').then((m) => m.TipOfTheWeekPageModule)
	},
	{
		path: 'result-calculation',
		loadChildren: () => import('./pretraining/result-calculation/result-calculation.module').then((m) => m.ResultCalculationPageModule)
	},
	{
		path: 'skill-type-explanation',
		loadChildren: () => import('./pretraining/skill-type-explanation/skill-type-explanation.module').then((m) => m.SkillTypeExplanationPageModule)
	},
	{
		path: 'long-term-goals',
		loadChildren: () => import('./pretraining/long-term-goals/long-term-goals.module').then((m) => m.LongTermGoalsPageModule)
	},
	{
		path: 'journey-behaviors',
		loadChildren: () => import('./pretraining/journey-behaviors/journey-behaviors.module').then((m) => m.JourneyBehaviorsPageModule)
	},
	{
		path: 'journey-behavior-selection',
		loadChildren: () => import('./pretraining/journey-behavior-selection/journey-behavior-selection.module').then((m) => m.JourneyBehaviorSelectionPageModule)
	},
	{
		path: 'journey-behavior-selection-modification',
		loadChildren: () => import('./pretraining/journey-behavior-selection-modification/journey-behavior-selection-modification.module').then((m) => m.JourneyBehaviorSelectionModificationPageModule)
	},
	{
		path: 'new-challenge',
		loadChildren: () => import('./daily-challenge/new-challenge/new-challenge.module').then((m) => m.NewChallengePageModule)
	},
	{
		path: 'daily-challenge-success-animation',
		loadChildren: () => import('./daily-challenge/daily-challenge-success-animation/daily-challenge-success-animation.module').then((m) => m.DailyChallengeSuccessAnimationPageModule)
	},
	{
		path: 'daily-challenge-summary',
		loadChildren: () => import('./daily-challenge/daily-challenge-summary/daily-challenge-summary.module').then((m) => m.DailyChallengeSummaryPageModule)
	},
	{
		path: 'rejection-modal',
		loadChildren: () => import('./daily-challenge/rejection-modal/rejection-modal.module').then((m) => m.RejectionModalComponentModule)
	},
	{
		path: 'daily-challenge-completion-feedback',
		loadChildren: () => import('./daily-challenge/daily-challenge-completion-feedback/daily-challenge-completion-feedback.module').then((m) => m.DailyChallengeCompletionFeedbackPageModule)
	},
	{
		path: 'goal-behavior-intro',
		loadChildren: () => import('./pretraining/goal-behavior-intro/goal-behavior-intro.module').then((m) => m.GoalBehaviorIntroPageModule)
	},
	{
		path: 'profile-setup',
		loadChildren: () => import('./pretraining/profile-setup/profile-setup.module').then((m) => m.ProfileSetupPageModule)
	},
	{
		path: 'gamification-touchpoint',
		loadChildren: () => import('./pretraining/gamification-touchpoint/gamification-touchpoint.module').then((m) => m.GamificationTouchpointPageModule)
	},
	{
		path: 'space-map',
		loadChildren: () => import('./space-map/space-map.module').then((m) => m.SpaceMapPageModule)
	},
	{
		path: 'player-progression',
		loadChildren: () => import('./player-progression/player-progression.module').then((m) => m.PlayerProgressionPageModule)
	},
	{
		path: 'journey',
		loadChildren: () => import('./journey/journey.module').then((m) => m.JourneyPageModule)
	},
	{
		path: 'duels',
		loadChildren: () => import('./duels/duels.module').then((m) => m.DuelsPageModule)
	},
	{
		path: 'lottery-update',
		loadChildren: () => import('./lottery/lottery-update/lottery-update.module').then((m) => m.LotteryUpdatePageModule)
	},
	{
		path: 'lottery-result',
		loadChildren: () => import('./lottery/lottery-result/lottery-result.module').then((m) => m.LotteryResultPageModule)
	},
	{
		path: 'multi-package-hub-screen',
		loadChildren: () => import('./multi-package-hub/multi-package-hub.module').then((m) => m.MultiPackageHubPageModule)
	},
	{
		path: 'self-service-login',
		loadChildren: () => import('./self-service/self-service-login/self-service-login.module').then((m) => m.SelfServiceLoginPageModule)
	},
	{
		path: 'self-service-training-setup',
		loadChildren: () => import('./self-service/self-service-training-setup/self-service-training-setup.module').then((m) => m.SelfServiceTrainingSetupPageModule)
	},
	{
		path: 'self-service-team-invite',
		loadChildren: () => import('./self-service/self-service-team-invite/self-service-team-invite.module').then((m) => m.SelfServiceTeamInvitePageModule)
	},
	{
		path: 'self-service-summary',
		loadChildren: () => import('./self-service/self-service-summary/self-service-summary.module').then((m) => m.SelfServiceSummaryPageModule)
	},
	{
		path: 'self-service-team-overview',
		loadChildren: () => import('./self-service/self-service-team-overview/self-service-team-overview.module').then((m) => m.SelfServiceTeamOverviewPageModule)
	}

	// {
	//   path: 'microtraining-info',
	//   loadChildren: () => import('./training/training-suggestion/microtraining-info/microtraining-info.module').then( m => m.MicrotrainingInfoPageModule)
	// }
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
