import { Injectable } from '@angular/core';
import { DailyChallenge } from '@app/_models/daily-challenge';
import { DailyChallengeStats } from '@app/_models/daily-challenge-stats';
import { ProgressDetails } from '@app/_models/progress-details';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { GamificationService } from './gamification.service';
import { HttpParams } from '@angular/common/http';
import { Challenge } from '@app/_models/challenge';
import { StepDefinition } from '@app/_models/stepDefinition';
import {
	PreviousStepInfoAudio,
	PreviousStepInfoGapText,
	PreviousStepInfoInfographic,
	PreviousStepInfoMatchingPair,
	PreviousStepInfoQuiz,
	PreviousStepInfoTextual,
	PreviousStepInfoVideo,
	ProgressionStateDailyChallenge,
	ProgressionStateTraining
} from '@app/_models/progression-state';
import { StepResult } from '@app/_models/step-result';
import { StepResourceType } from '@app/_models/step-resource-type';

@Injectable({
	providedIn: 'root'
})
export class DailyChallengeService {
	private dailyChallenge: DailyChallenge;
	private dailyChallengeStats: DailyChallengeStats;

	private challenge: Challenge;

	private hasSeenNoteRMIForDailyChallenge: boolean = false;

	//Data
	private currentDailyChallengeStepSubject: BehaviorSubject<StepDefinition>;
	public currentDailyChallengeStepItem: Observable<StepDefinition>;

	private dailyChallengeProgressionStateSubject: BehaviorSubject<ProgressionStateDailyChallenge>;
	public dailyChallengeProgressionStateItem: Observable<ProgressionStateDailyChallenge>;

	private dailyChallengeProgressionState: ProgressionStateDailyChallenge;

	private dailyChallengeSetTimeQuestionShownSubject: BehaviorSubject<boolean>;
	dailyChallengeSetTimeQuestionShown: Observable<boolean>;

	public currentDailyChallengeStepIndex: number;
	public useRealProgressionState: boolean = false;

	public isHistoryTraining: boolean = false;
	public dailyChallengeFailed: boolean = false;

	constructor(
		private apiService: ApiService,
		private gamificationService: GamificationService
	) {
		this.currentDailyChallengeStepSubject = new BehaviorSubject<StepDefinition>(null);
		this.currentDailyChallengeStepItem = this.currentDailyChallengeStepSubject.asObservable();

		this.dailyChallengeProgressionStateSubject = new BehaviorSubject<ProgressionStateDailyChallenge>(null);
		this.dailyChallengeProgressionStateItem = this.dailyChallengeProgressionStateSubject.asObservable();

		this.dailyChallengeProgressionStateSubject.subscribe((progressionState) => {
			this.dailyChallengeProgressionState = progressionState;
		});

		this.dailyChallengeSetTimeQuestionShownSubject = new BehaviorSubject<boolean>(null);
		this.dailyChallengeSetTimeQuestionShown = this.dailyChallengeSetTimeQuestionShownSubject.asObservable();
	}

	async loadDaillyChallenge(): Promise<DailyChallenge> {
		this.isHistoryTraining = false;
		this.useRealProgressionState = true;

		const response = await this.apiService
			.get<DailyChallenge>('/challenge')
			.pipe(
				map((dailyChallenge) => {
					console.log(`Daily Challenge First Load`, dailyChallenge);
					dailyChallenge.challenge.daily_challenge_steps = dailyChallenge.challenge.daily_challenge_steps.sort((a, b) => a.order_number - b.order_number);
					this.dailyChallenge = dailyChallenge;
					this.challenge = dailyChallenge.challenge;
					return dailyChallenge;
				})
			)
			.toPromise();

		if (!response) {
			return Promise.reject();
		}

		console.log(`Daily Challenge saved value`, this.dailyChallenge);

		this.currentDailyChallengeStepIndex = 0;

		this.dailyChallengeProgressionStateSubject.next(this.dailyChallenge.challenge.progression_state);
		if (this.dailyChallengeProgressionState.order_number != null) {
			// if order_number is on last daily challenge step step, the DailyChallenge is done
			if (this.dailyChallengeProgressionState.order_number >= this.dailyChallenge.challenge.daily_challenge_steps.length) {
				this.currentDailyChallengeStepIndex = this.challenge.progression_state.order_number - 1;
			} else {
				this.currentDailyChallengeStepIndex = this.challenge.progression_state.order_number;
			}
		}

		this.currentDailyChallengeStepSubject.next(this.dailyChallenge.challenge.daily_challenge_steps[this.currentDailyChallengeStepIndex]);
		console.group();
		console.log(`Progression State First Load`, this.dailyChallengeProgressionState);
		console.log(`Current daily challenge Step First Load`, this.currentDailyChallengeStepSubject.value);
		console.groupEnd();
		return Promise.resolve(response);
	}

	async loadDaillyChallengeForRedoByUUID(daily_challenge_UUID: string): Promise<Challenge> {
		this.isHistoryTraining = true;
		let params = new HttpParams();
		params = params.set('daily_challenge_uuid', daily_challenge_UUID);

		this.useRealProgressionState = false;

		const response = await this.apiService
			.get<Challenge>('/history/redo/daily_challenge', params)
			.pipe(
				map((challenge) => {
					console.log(`History Daily Challenge First Load`, challenge);
					challenge.daily_challenge_steps = challenge.daily_challenge_steps.sort((a, b) => a.order_number - b.order_number);
					this.challenge = challenge;
					return challenge;
				})
			)
			.toPromise();

		if (!response) {
			return Promise.reject();
		}

		console.log(`History Daily Challenge saved value`, this.challenge);

		this.currentDailyChallengeStepIndex = 0;

		this.dailyChallengeProgressionStateSubject.next({ order_number: null, previous_steps_info: [] });

		this.currentDailyChallengeStepSubject.next(this.challenge.daily_challenge_steps[this.currentDailyChallengeStepIndex]);
		console.group();
		console.log(`Progression State First Load`, this.dailyChallengeProgressionState);
		console.log(`Current daily challenge Step First Load`, this.currentDailyChallengeStepSubject.value);
		console.groupEnd();
		return Promise.resolve(response);
	}

	saveChallengeRejection(): Promise<any> {
		var postData = { challenge_id: this.challenge.pk, accepted: false, push_enabled: false, blocker_date: '' };

		return this.apiService.post<any>('/challenge/accept', postData).toPromise();
	}

	async saveDailyChallengeStepCompleted(dailyChallengeStepOrderNumber: number, result: StepResult): Promise<any> {
		if (!this.useRealProgressionState) {
			this.mockDailyChallengeStepCompleted(dailyChallengeStepOrderNumber, result);
			return;
		}
		const postData = {
			daily_challenge_id: this.challenge.pk,
			progression_state_value: dailyChallengeStepOrderNumber,
			result
		};

		const response = await this.apiService.post<{ progression_state: ProgressionStateTraining }>('/challenge/state_update', postData).toPromise();
		if (!response) {
			return;
		}

		if (this.useRealProgressionState) {
			this.dailyChallengeProgressionStateSubject.next(response.progression_state);
			console.log(`Progression State Updated`, response.progression_state);
		}
		return;
	}

	mockDailyChallengeStepCompleted(dailyChallengeStepOrderNumber: number, result: StepResult) {
		let steptype;
		let currentStep;

		steptype = this.challenge.daily_challenge_steps[dailyChallengeStepOrderNumber - 1].resourcetype;
		currentStep = this.challenge.daily_challenge_steps[dailyChallengeStepOrderNumber - 1];

		let newProgressionState: ProgressionStateDailyChallenge = structuredClone(this.dailyChallengeProgressionState);

		switch (steptype) {
			case StepResourceType.StepTextual:
			case StepResourceType.StepInfographic:
			case StepResourceType.StepVideo:
			case StepResourceType.StepAudio:
				newProgressionState.previous_steps_info.push({
					order_number: dailyChallengeStepOrderNumber,
					step_type: steptype,
					result: []
				} as PreviousStepInfoTextual | PreviousStepInfoInfographic | PreviousStepInfoVideo | PreviousStepInfoAudio);
				break;
			case StepResourceType.StepQuiz:
				if ('quiz' in result) {
					const quizResult = result as { quiz: { answer_id: number } };
					if (currentStep.resourcetype === StepResourceType.StepQuiz) {
						const correctChoice = currentStep.answer_choices.find((choice) => choice.correct);
						newProgressionState.previous_steps_info.push({
							order_number: dailyChallengeStepOrderNumber,
							step_type: StepResourceType.StepQuiz,
							result: [
								{
									step_quiz: dailyChallengeStepOrderNumber,
									answer_choice: quizResult.quiz.answer_id,
									correct_choice: correctChoice?.pk === quizResult.quiz.answer_id
								}
							]
						} as PreviousStepInfoQuiz);
					}
				}
				break;
			case StepResourceType.StepMatchingPairs:
				if ('matching_pairs' in result) {
					const matchingPairsResult = result as { matching_pairs: { pair_id: number; tries_till_right_answer: number }[] };
					if (currentStep.resourcetype === StepResourceType.StepMatchingPairs) {
						newProgressionState.previous_steps_info.push({
							order_number: dailyChallengeStepOrderNumber,
							step_type: StepResourceType.StepMatchingPairs,
							result: matchingPairsResult.matching_pairs
						} as PreviousStepInfoMatchingPair);
					}
				}
				break;
			case StepResourceType.StepGapText:
				const gapTextResult = result as { gap_text: { gap_id: number; tries_till_right_answer: number }[] };
				if (currentStep.resourcetype === StepResourceType.StepGapText) {
					newProgressionState.previous_steps_info.push({
						order_number: dailyChallengeStepOrderNumber,
						step_type: StepResourceType.StepGapText,
						result: { gap_text: gapTextResult.gap_text }
					} as PreviousStepInfoGapText);
				}
				break;
			default:
				break;
		}

		newProgressionState.order_number = dailyChallengeStepOrderNumber;
		this.dailyChallengeProgressionStateSubject.next(newProgressionState);
	}

	setTimeAndPush(challengeTime: string, enablePush: boolean): Promise<any> {
		var postData = { challenge_id: this.challenge.pk, accepted: true, push_enabled: enablePush, blocker_date: challengeTime };

		return this.apiService.post<any>('/challenge/accept', postData).toPromise();
	}

	//This send the challenge accepted event with the blocker date set to now
	setStarted(): Promise<any> {
		new Date().toISOString();
		const postData = { challenge_id: this.challenge.pk, accepted: true, push_enabled: false, blocker_date: new Date().toISOString() };
		return this.apiService.post<any>('/challenge/accept', postData).toPromise();
	}

	setCompletedSuccessfull(): Promise<any> {
		var postData = { challenge_id: this.challenge.pk, success: true };

		this.gamificationService.resetProgressDetails();

		return this.apiService
			.post<ProgressDetails>('/challenge/complete', postData)
			.pipe(
				map((progressDetails) => {
					this.gamificationService.progressDetails = progressDetails;
				})
			)
			.toPromise();
	}

	setCompletedFailed(): Promise<any> {
		var postData = { challenge_id: this.challenge.pk, success: false };

		this.gamificationService.resetProgressDetails();

		return this.apiService
			.post<ProgressDetails>('/challenge/complete', postData)
			.pipe(
				map((progressDetails) => {
					this.gamificationService.progressDetails = progressDetails;
				})
			)
			.toPromise();
	}

	getDailyChallenge(): DailyChallenge {
		return this.dailyChallenge;
	}

	getCurrentDailyChallenge(): Challenge {
		return this.challenge;
	}

	getCurrentDailyChallengeStepIndex() {
		return this.currentDailyChallengeStepIndex;
	}

	getDailyChallengeStats(): DailyChallengeStats {
		return this.dailyChallengeStats;
	}

	displaySetTimeDailyChallengeModal() {
		this.dailyChallengeSetTimeQuestionShownSubject.next(true);
	}

	resetRejectDailyChallengenModalShown() {
		this.dailyChallengeSetTimeQuestionShownSubject.next(false);
	}

	getHasSeenNoteRMIForDailyChallenge(): boolean {
		return this.hasSeenNoteRMIForDailyChallenge;
	}

	setHasSeenNoteRMIForDailyChallenge(value: boolean) {
		this.hasSeenNoteRMIForDailyChallenge = value;
	}

	getDailyChallengeLength(): number {
		return this.challenge.daily_challenge_steps.length;
	}

	incrementCurrentDailyChallengeStep() {
		this.currentDailyChallengeStepSubject.next(this.challenge.daily_challenge_steps[++this.currentDailyChallengeStepIndex]);
	}

	decrementCurrentDailyChallengeStep() {
		this.currentDailyChallengeStepSubject.next(this.challenge.daily_challenge_steps[--this.currentDailyChallengeStepIndex]);
	}

	setDailyChallengeFailed() {
		this.dailyChallengeFailed = true;
	}

	resetDailyChallengeFailed() {
		this.dailyChallengeFailed = false;
	}

	getDailyChallengeFailed(): boolean {
		return this.dailyChallengeFailed;
	}
}
