import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Filesystem, Directory, Encoding, ReadFileResult } from '@capacitor/filesystem';
import { ApiService } from './api.service';
import { StorageServiceError } from '@app/_error/errors';

type StorageType = 'build' | 'otc' | 'inactive';
type StorageObject = {
	location: StorageType;
	path: string;
	file_extension: string;
	local_path: string;
};

type LoadFileReturn =
	| {
			data: any;
			status: string;
			message: string;
	  }
	| Promise<string>;

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	private platform = Capacitor.getPlatform();
	constructor(private apiService: ApiService) {
		console.log('platform', this.platform);
	}

	private convertBlobToBase64 = (blob: Blob): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onerror = reject;
			reader.onload = () => {
				resolve(reader.result as string);
			};
			reader.readAsDataURL(blob);
		});

	async useOTC(path: string, file_extension: string) {
		console.debug('useOTC', path);
		let res;
		try {
			res = await this.apiService.getAsset(`${path}`, file_extension);
		} catch (error) {
			console.error(error);
			return { status: 'error while using OTC', message: error, data: res };
		}
		console.debug('res useOtc', JSON.stringify(res));
		return res;
	}

	async getValue(path: string, file_extension: string, platform: string = this.platform) {
		let value = await this.useOTC(path, file_extension);
		console.debug('getValue', JSON.stringify(value));
		switch (true) {
			case file_extension === 'json':
				if (platform !== 'web') {
					value = btoa(JSON.stringify(value.body));
					console.debug('value as base64', value);
				} else {
					value = JSON.stringify(value.body);
				}
				break;
			default:
				if (platform !== 'web') {
					console.log('before value as base64 default', value);
					value = await this.convertBlobToBase64(value.body);
					console.log('after value as base64 default', value);
				} else {
					value = value.body;
				}
				break;
		}
		return value;
	}

	checkIfFileExists = async (storageObject: StorageObject) => {
		const { path, local_path, file_extension } = storageObject;
		try {
			if (!local_path) throw new StorageServiceError('local_path is not defined');
			const file = await Filesystem.stat({ path: local_path, directory: Directory.Data }).catch((error) => {
				throw new StorageServiceError(error);
			});
			return true;
		} catch (checkDirException) {
			if (checkDirException == 'Error: Entry does not exist.' || checkDirException == 'Error: File does not exist') {
				console.error('checkIfFileExists File does not exist');
				return false;
			} else {
				console.error('checkIfFileExists Different Error', checkDirException);
				return false;
			}
		}
	};

	storeFile = async (storageObject: StorageObject) => {
		const { path, local_path, file_extension } = storageObject;
		const value = await this.getValue(path, file_extension);
		let res;
		console.debug('storeFile', value);
		try {
			res = await Filesystem.writeFile({
				path: local_path,
				data: value,
				directory: Directory.Data,
				encoding: Encoding.UTF8,
				recursive: true
			}).catch((error) => {
				throw new StorageServiceError('Error writing to filesystem: ' + error);
			});
		} catch (error) {
			return { status: 'error while Storing', message: error, data: res };
		}
		return { status: 'success', message: 'File written successfully', data: res };
	};

	readFile = async (path: string, directory: Directory = Directory.Data) => {
		const contents = await Filesystem.readFile({
			path: path,
			directory: directory
			// encoding: Capacitor.isNativePlatform() ? undefined : Encoding.UTF8
		}).catch((error) => {
			if (error == 'Error: File does not exist.') {
				console.log('File does not exist');
				return { data: null, status: 'error', message: 'File does not exist' };
			}
		});
		if (!contents.data) {
			return { data: null, status: 'error', message: 'Content is null' };
		}
		return { data: contents.data, status: 'success' };
	};

	loadFile = async (storageObject: StorageObject): Promise<string | { data: any; status: string; message: string }> => {
		const { local_path, file_extension } = storageObject;
		let data = await this.readFile(local_path);
		console.debug('loadFile', storageObject, data);
		if (data.status === 'error') {
			data = await this.storeFile(storageObject).then(async (res): Promise<{ data: any; status: string; message: string }> => {
				return (await this.loadFile(storageObject)) as { data: any; status: string; message: string };
			});
		}
		if (data.status === 'success') {
			switch (true) {
				case file_extension === 'json':
					if (this.platform === 'web') {
						console.debug('loadFile', data);
						return JSON.parse(data.data);
					} else {
						const output = JSON.parse(atob(atob(data.data)));
						console.debug('loadFile json', output);
						return output;
					}
				default:
					if (this.platform === 'web') {
						console.debug('loadFile', data.data);
						return await this.convertBlobToBase64(data.data);
					} else {
						const value = atob(data.data);
						console.debug('loadFile default after atob', value);
						return value;
					}
			}
		}
	};
}
