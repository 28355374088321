import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Goal } from '@app/_models/goal';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { MicrotrainingQuestion } from '@app/_models/microtrainingQuestion';
import { map } from 'rxjs/operators';
import { SingleTrainingContent } from '@app/_models/singleTrainingContent';
import { User } from '@app/_models/user';
import { QuestionResponse } from '@app/_models/question-response';
import { PackageService } from './package.service';
import { MicrotrainingsSelection } from '@app/_models/microtrainings-selection';
import { CurrentWeek } from '@app/_models/current-week';
import { Skill } from '@app/_models/skill';
import { Microtraining } from '@app/_models/microtraining';
import { context } from '@app/_xstate/savvi-machine.config';
import { StatsPerWeek } from '@app/_models/stats-per-week';
import { GoalSelectionAndFeedback } from '@app/_models/goal-selection-and-feedback';
import { SkillPerWeek } from '@app/_models/skill-per-week';
import { SkillSelection } from '@app/_models/skill-selection';
import { ProfileService } from './profile.service';
import { addDays, startOfWeek } from 'date-fns';
import { de } from 'date-fns/locale';
import { ProgressDetails } from '@app/_models/progress-details';
import { GamificationService } from './gamification.service';
import { MicrotrainingRecommendations } from '@app/_models/microtraining-recommendations';

@Injectable({
	providedIn: 'root'
})
export class SingleTrainingService {
	private currentWeek: CurrentWeek;
	private previousWeekStatus: MicrotrainingsSelection;
	private currentWeekStatus: MicrotrainingsSelection;
	private goalsCompleted: Goal;
	private microtrainingQuestions: MicrotrainingQuestion[];
	private microtrainings: Microtraining[];
	private microtrainingSuggestion: Microtraining;
	private microtrainingRecommendations: MicrotrainingRecommendations;
	private fixedOrderMicrotraining: Microtraining;
	private selectedSkill: Skill;
	private currentMicrotraining: Microtraining;
	private currentSkill: Skill;
	private allSkills: Skill[];
	private skillsPerWeek: SkillPerWeek[];
	private statsPerWeek: StatsPerWeek[];
	private microtrainingLibrary: Skill[];
	private microtrainingLibraryMicrotrainings: Map<number, Microtraining[]>;
	private appComingBackFromFullSkillSelection: boolean = false;

	private _isNextMicrotrainingFixedOrder: boolean = false;

	constructor(
		private apiService: ApiService,
		private packageService: PackageService,
		private profileService: ProfileService,
		private gamificationService: GamificationService
	) {}

	loadCurrentWeek(packageId: number): Promise<CurrentWeek> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());

		return this.apiService
			.get<CurrentWeek>('/microtrainings/currentWeek', params)
			.pipe(map((weekInfo) => (this.currentWeek = weekInfo)))
			.toPromise();
	}

	loadPreviousWeekStatus(packageId: number): Promise<MicrotrainingsSelection | void> {
		if (this.currentWeek.week - 1 < 1) {
			return new Promise((resolve, _reject) => resolve());
		}

		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());
		params = params.set('week', (this.currentWeek.week - 1).toString());

		return this.apiService
			.get<MicrotrainingsSelection>('/microtrainings/status', params)
			.pipe(map((previousWeekStatus) => (this.previousWeekStatus = previousWeekStatus)))
			.toPromise();
	}

	loadCurrentGoal(packageId: number): Promise<Goal | void> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());
		params = params.set('week', (this.currentWeek.week - 1).toString());

		return this.apiService
			.get<Goal>('/daily_challenge_goal/selection')
			.pipe(map((goalsCompleted) => (this.goalsCompleted = goalsCompleted)))
			.toPromise();
	}

	loadCurrentWeekStatus(packageId: number): Promise<MicrotrainingsSelection> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());
		params = params.set('week', this.currentWeek.week.toString());

		//TODO: not needed anymore when API returns skill directly. Then on "selectSkill" the skill should just be set in the currentWeekStatus field and will automatically be reset in the next week when currentWeekStatus is loaded from API again.
		//this.currentSkill = null;
		return this.apiService
			.get<MicrotrainingsSelection>('/microtrainings/status', params)
			.pipe(
				map(
					(currentWeekStatus) =>
						(this.currentWeekStatus = currentWeekStatus
							? currentWeekStatus
							: {
									package: packageId,
									week: this.currentWeek.week,
									microtraining: null,
									single_training_completed: false,
									group_training_completed: false
								})
				)
			)
			.toPromise();
	}

	isEndOfWeek() {
		var today = new Date();
		//var fifth= today.getDate()-today.getDate()+ 4;
		var friday = addDays(startOfWeek(today, { locale: de }), 4);
		today = new Date();
		//console.log('Is EndofWeek was called and returned: '+ (today>=friday).toString() + " today is: " + today.toString() + " friday is: " + friday.toString());
		return today >= friday;
	}

	isLastWeek() {
		//console.log("isLastWeek returned: " + (this.getCurrentWeek() === this.getPackageTotalWeeks()))
		return this.getCurrentWeek() === this.getPackageTotalWeeks();
	}

	isLastWeekAndIsTrainingCompleted(): boolean {
		// console.log('Is isLatWeekAndTrainingCompleted was called and returned: ' + (this.isLastWeek() && this.getCurrentWeekCompleted()).toString() + ' with isLastWeek: ' + (this.isLastWeek()).toString() + ' and getCurrentWeekCompleted:' + this.getCurrentWeekCompleted().toString());
		return this.isLastWeek() && this.getCurrentWeekCompleted();
	}

	isLastWeekAndIsTrainingCompletedAndIsAfterFriday12oclock(): boolean {
		return this.isLastWeekAndIsTrainingCompleted() && this.currentWeek.last_friday_12_oclock * 1000 < new Date().getTime();
	}

	loadMicrotrainingQuestions(packageId: number, week: number, microtraining: Microtraining | null): Promise<MicrotrainingQuestion[]> {
		let params = new HttpParams();
		params = params.set('package_id', packageId.toString());
		params = params.set('week', week.toString());
		if (this.previousWeekStatus) {
			params = params.set('microtraining_id', this.previousWeekStatus.microtraining.pk.toString());
		}

		return this.apiService
			.get<MicrotrainingQuestion[]>('/questions/microtraining', params)
			.pipe(map((microtrainingQuestions) => (this.microtrainingQuestions = microtrainingQuestions)))
			.toPromise();
	}

	saveMicrotrainingQuestionAnswers(): Promise<QuestionResponse[]> {
		const postData = this.microtrainingQuestions.map((question) => ({ pk: question.pk, answer: question.answer }));

		return this.apiService.put<QuestionResponse[]>(`/questions/microtrainingResponses`, postData).toPromise();
	}

	loadSkills(): Promise<Skill[]> {
		let params = new HttpParams();
		params = params.set('package_id', this.packageService.getCurrentPackage().pk.toString());

		return this.apiService
			.get<Skill[]>('/journey/skills/microtrainings', params)
			.pipe(map((allSkills) => (this.allSkills = allSkills)))
			.toPromise();
	}

	setSelectedSkill(skillPk: number) {
		this.selectedSkill = this.allSkills.find((skill) => skill.pk == skillPk);
	}

	getSelectedSkill(): Skill {
		return this.selectedSkill;
	}

	// TODO remove
	selectSkill(skillPk: number): Promise<any> {
		const postData = {
			package_id: this.packageService.getCurrentPackage().pk,
			week: this.currentWeek.week,
			skill_id: skillPk //this.skillsPerWeek.find((skillPerWeek) => skillPerWeek.recommended == true).skill.pk
		};

		this.currentSkill = this.skillsPerWeek.find((skillPerWeek) => skillPerWeek.skill.pk == skillPk).skill;

		return this.apiService
			.post<any>(`/skills/selection`, postData)
			.pipe(
				map((response) => {
					return response;
				})
			)
			.toPromise();
	}

	//TODO remove if not needed anymore
	loadSkillSelection(): Promise<Skill> {
		let params = new HttpParams();
		params = params.set('package_id', this.packageService.getCurrentPackage().pk.toString());
		params = params.set('week', this.currentWeek.week.toString());

		return this.apiService
			.get<SkillSelection>('/skills/selection', params)
			.pipe(map((skillSelection) => (this.currentSkill = skillSelection ? skillSelection.skill : null)))
			.toPromise();
	}

	// TODO remove
	loadMicrotrainings(): Promise<Microtraining[]> {
		let params = new HttpParams();
		params = params.set('package_id', this.packageService.getCurrentPackage().pk.toString());
		params = params.set('open', 'true');
		params = params.set('skill_id', this.currentSkill.pk.toString());

		return this.apiService
			.get<Microtraining[]>('/microtrainings', params)
			.pipe(map((microtrainings) => (this.microtrainings = microtrainings)))
			.toPromise();
	}

	loadMicrotrainingLibrary(): Promise<Microtraining[][]> {
		console.log('loadMicrotrainingLibrary');

		this.microtrainingLibrary = [];
		let promises: Promise<Microtraining[]>[] = [];
		//this.profileService.getCurrentUserStats()[0].skillsPerWeek.forEach((skillPerWeek) => this.microtrainingLibrary.push(skillPerWeek.skill));
		this.packageService.getSkills().forEach((skill) => this.microtrainingLibrary.push(skill));
		console.log('MicrotrainingLibrary; Package_id: ' + this.packageService.getCurrentPackage().pk.toString());
		let params = new HttpParams();
		params = params.set('package_id', this.packageService.getCurrentPackage().pk.toString());
		params = params.set('open', 'false');
		for (const skill of this.microtrainingLibrary) {
			console.log('MicrotrainingLibrary; Skill: ' + skill.pk.toString());

			params = params.set('skill_id', skill.pk.toString());

			promises.push(this.microtrainingPromise(skill.pk, params));
		}
		console.log('MicrotrainingLibrary; Promises: ' + promises.length.toString());
		return Promise.all(promises);
	}

	microtrainingPromise(skillPk: number, params: HttpParams): Promise<Microtraining[]> {
		return this.apiService
			.get<Microtraining[]>('/microtrainings', params)
			.pipe(map((microtrainings) => (this.microtrainingLibrary.find((skillItem) => skillItem.pk === skillPk).microtrainings = microtrainings)))
			.toPromise();
	}

	loadFixedOrderStatus(): Promise<any> {
		let params = new HttpParams();
		params = params.set('package_id', this.packageService.getCurrentPackage().pk.toString());

		return this.apiService
			.get<any>('/training/is_fixed_order', params)
			.pipe(map((isNextMicrotrainingFixedOrder) => (this._isNextMicrotrainingFixedOrder = isNextMicrotrainingFixedOrder.is_fixed_order)))
			.toPromise();
	}

	loadFixedOrderMicrotraining(): Promise<Microtraining> {
		let params = new HttpParams();
		params = params.set('package_id', this.packageService.getCurrentPackage().pk.toString());

		return this.apiService
			.get<Microtraining>('/microtraining/fixed_order_recommendation', params)
			.pipe(map((fixedOrderMicrotraining) => (this.fixedOrderMicrotraining = fixedOrderMicrotraining)))
			.toPromise();
	}

	loadMicrotrainingRecommendations(): Promise<MicrotrainingRecommendations> {
		return this.apiService
			.get<MicrotrainingRecommendations>('/microtraining/recommendation')
			.pipe(map((microtrainingRecommendations) => (this.microtrainingRecommendations = microtrainingRecommendations)))
			.toPromise();
	}

	// TODO remove
	// N.B. at the moment we are using the suggested microtraining as the "default" microtraining
	// and not allowing users to change it, therefore the value returned from the API here will
	// be used for all microtraining related content for the current week for this user.
	//
	// See `selectSuggestedMicrotraining` and `saveSingleTrainingCompleted`
	loadMicrotrainingSuggestion() {
		let params = new HttpParams();
		params = params.set('package_id', this.packageService.getCurrentPackage().pk.toString());

		return this.apiService
			.get<Microtraining>('/microtrainings/suggestion', params)
			.pipe(map((microtrainingSuggestion) => (this.microtrainingSuggestion = microtrainingSuggestion)))
			.toPromise();
	}

	// TODO remove
	selectSuggestedMicrotraining() {
		const postData = {
			package_id: this.packageService.getCurrentPackage().pk,
			week: this.currentWeek.week,
			microtraining_id: this.microtrainingSuggestion.pk
		};

		this.currentWeekStatus.microtraining = this.microtrainingSuggestion;

		return this.apiService.post<MicrotrainingsSelection>(`/microtrainings/selection`, postData);
	}

	selectMicrotraining(microtraining: Microtraining) {
		const postData = {
			package_id: this.packageService.getCurrentPackage().pk,
			week: this.currentWeek.week,
			microtraining_id: microtraining.pk
		};

		this.currentWeekStatus.microtraining = microtraining;

		return this.apiService.post<MicrotrainingsSelection>(`/microtrainings/selection`, postData).toPromise();
	}

	resetMicrotraining() {
		this.currentWeekStatus.microtraining = null;
	}

	saveExerciseExplanation(user: User, doNotShowAgain: boolean): Promise<any> {
		const postData = {
			skip_exercise_intro_screen: doNotShowAgain
		};

		return this.apiService
			.put(`/userPreferences`, postData)
			.pipe(
				map((user_preferences) => {
					user.user_preferences = user_preferences;
				})
			)
			.toPromise();
	}

	setMicrotrainingQuestionAnswer(microtrainingQuestionIndex: number, answer: number) {
		if (this.microtrainingQuestions[microtrainingQuestionIndex]) {
			console.log('Setting answer for question ' + microtrainingQuestionIndex + ' to ' + answer);
			this.microtrainingQuestions[microtrainingQuestionIndex].answer = answer;
		} else {
			console.log('Index ' + microtrainingQuestionIndex + ' does not exist as a question.');
		}
	}

	saveGoalSelection(goal): Promise<any> {
		const postData = {
			amount_of_challenges_selected: goal
		};

		return this.apiService
			.post<any>(`/daily_challenge_goal/selection`, postData)
			.pipe(
				map((response) => {
					return response;
				})
			)
			.toPromise();
	}

	getCurrentGoals(): Goal {
		if (this.goalsCompleted) {
			return this.goalsCompleted;
		} else {
			return null;
		}
	}

	getMicrotrainingQuestions(): MicrotrainingQuestion[] {
		return this.microtrainingQuestions;
	}

	getCurrentWeekCompleted(): boolean {
		return this.currentWeekStatus.single_training_completed;
	}

	getCurrentWeekStatus(): MicrotrainingsSelection {
		return this.currentWeekStatus;
	}
	setCurrentWeekStatus(microtrainingsSelection: MicrotrainingsSelection) {
		this.currentWeekStatus = microtrainingsSelection;
	}

	getPreviousWeekStatus(): MicrotrainingsSelection {
		return this.previousWeekStatus;
	}
	setPreviousWeekStatus(microtrainingsSelection: MicrotrainingsSelection) {
		this.previousWeekStatus = microtrainingsSelection;
	}

	getCurrentWeek(): number {
		return this.currentWeek.week;
	}

	getTodayIsMonday(): boolean {
		return this.currentWeek.is_monday;
	}

	getPackageTotalWeeks(): number {
		return this.currentWeek.total_weeks;
	}

	getSkillsPerWeek(): SkillPerWeek[] {
		return this.skillsPerWeek;
	}

	getAllSkills(): Skill[] {
		return this.allSkills;
	}

	getCurrentSkill(): Skill {
		return this.currentSkill;
	}

	getMicrotrainings(): Microtraining[] {
		return this.microtrainings;
	}

	getMicrotrainingSuggestion(): Microtraining {
		return this.microtrainingSuggestion;
	}

	getFixedOrderMicrotraining(): Microtraining {
		return this.fixedOrderMicrotraining;
	}

	getMicrotrainingLibrary(): Skill[] {
		return this.microtrainingLibrary;
	}

	getMicrotrainingRecommendations(): MicrotrainingRecommendations {
		return this.microtrainingRecommendations;
	}

	setIsAppComingBackFromFullSkillSelection() {
		console.log('setIsAppComingBackFromFullSkillSelection');
		this.appComingBackFromFullSkillSelection = true;
	}

	resetIsAppComingBackFromFullSkillSelection() {
		console.log('resetIsAppComingBackFromFullSkillSelection');
		this.appComingBackFromFullSkillSelection = false;
	}

	isAppComingBackFromFullSkillSelection(): boolean {
		return this.appComingBackFromFullSkillSelection;
	}

	isNextMicrotrainingFixedOrder(): boolean {
		return this._isNextMicrotrainingFixedOrder;
	}
}
