import { EventEmitter, Injectable, Output } from '@angular/core';
// import { userInfo } from 'os';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { AchievementReviewStats } from '@app/_models/achievement-review-stats';
import { map } from 'rxjs';
import { PackageCreation } from '@app/_models/package-creation';
import { Course } from '@app/_models/course';
import { SelfServicePackage } from '@app/_models/self-service-package';
import { Specialization } from '@app/_models/specialization';
import { SelfServiceStartupLocation } from '@app/_models/self-service-startup-location';
import { SelfServiceSubscription } from '@app/_models/self-service-subscription';
import { SelfServiceTeamMember } from '@app/_models/self-service-team-member';
import { SelfServiceTeam } from '@app/_models/self-service-team';

@Injectable({
	providedIn: 'root'
})
export class LicenseSelfService {
	//Subscription Status
	selfServiceStatus: SelfServiceSubscription;
	private selfServiceStartupLocation = SelfServiceStartupLocation.login;
	//TEAM CREATOR / LOGGED IN USER
	private teamCreator = { first_name: '', last_name: '', position: '' };
	//Course Options
	private subscriptionSettings: PackageCreation;
	private course: Course;
	private specialization: Specialization;
	private package: SelfServicePackage;
	//Team Members
	private selfServiceTeam: SelfServiceTeam;
	private teamMembers: SelfServiceTeamMember[];

	private hasSelectionChanged: boolean = false;

	constructor(private apiService: ApiService) {}

	loadSelfServicePackageCreation(): Promise<PackageCreation> {
		console.log('SelfServiceData loading');
		return this.apiService
			.get<PackageCreation>('/subscription/package_creation')
			.pipe(map((response) => (this.subscriptionSettings = response)))
			.toPromise();
	}

	loadSubscriptionStatus(): Promise<SelfServiceSubscription> {
		console.log('Subscription Status loading');
		return this.apiService
			.get<SelfServiceSubscription>('/subscription/info')
			.pipe(map((response) => (this.selfServiceStatus = response)))
			.toPromise();
	}

	loadSubscriptionTeam(): Promise<SelfServiceTeamMember[]> {
		console.log('Subscription Team Members loading');
		return this.apiService
			.get<SelfServiceTeamMember[]>('/subscription/users')
			.pipe(map((response) => (this.teamMembers = response)))
			.toPromise();
	}

	updateSubscriptionTeam(): Promise<any> {
		this.removeIncompleteMembers();

		var formattedTeamMembers = this.teamMembers.map((member) => ({
			first_name: member.first_name,
			last_name: member.last_name,
			email: member.email
		}));

		var postData = {
			team: formattedTeamMembers
		};

		return this.apiService
			.post<any>('/subscription/users', postData)
			.pipe(map((response) => (this.teamMembers = response)))
			.toPromise();
	}

	createSelfServicePackage(): Promise<any> {
		this.removeIncompleteMembers();
		console.log('Creating Self Service Package');
		var postData = {
			first_name: this.teamCreator.first_name,
			last_name: this.teamCreator.last_name,
			position: this.teamCreator.position,
			package_template: this.package.pk,
			team: this.teamMembers
		};

		return this.apiService.post<any>('/subscription/package_creation', postData).toPromise();
	}

	changeSelfServicePackage(): Promise<any> {
		console.log('Changing Self Service Package');
		var postData = {
			package_template: this.package.pk
		};

		return this.apiService.post<any>('/subscription/package_change', postData).toPromise();
	}

	updateSelfServiceTeam(): Promise<any> {
		this.removeIncompleteMembers();

		var formattedTeamMembers = this.teamMembers.map((member) => ({
			first_name: member.first_name,
			last_name: member.last_name,
			email: member.email
		}));

		var postData = {
			team: formattedTeamMembers
		};

		return this.apiService.post<any>('/subscription/users', postData).toPromise();
	}

	removeIncompleteMembers() {
		this.teamMembers = this.teamMembers.filter((member) => !!member.first_name && !!member.last_name && !!member.email);
	}

	getSubscriptionStatus() {
		return this.selfServiceStatus.subscription;
	}

	getSelfServiceStartupLocation(): SelfServiceStartupLocation {
		return this.selfServiceStartupLocation;
	}

	setSelfServiceStartupLocation(location: SelfServiceStartupLocation) {
		this.selfServiceStartupLocation = location;
	}

	setTeamCreator(first_name: string, last_name: string, position: string) {
		this.teamCreator.first_name = first_name;
		this.teamCreator.last_name = last_name;
		this.teamCreator.position = position;
	}

	getTeamCreator() {
		return this.teamCreator;
	}

	setCourse(course: Course) {
		this.course = course;
	}

	getCourse() {
		return this.course;
	}

	setSpecialization(specialization: Specialization) {
		this.specialization = specialization;
		console.log('Specialization set to:', specialization);
	}

	getHasSelectionChanged(): boolean {
		return this.hasSelectionChanged;
	}

	setHasSelectionChanged(value: boolean) {
		this.hasSelectionChanged = value;
	}

	getSpecialization() {
		return this.specialization;
	}

	setTeamMembers(members: SelfServiceTeamMember[]) {
		this.teamMembers = members;
	}

	getTeamMembers(): SelfServiceTeamMember[] {
		return this.teamMembers;
	}

	getMaxTeamMembers() {
		return this.subscriptionSettings.savvi_app_license_count;
	}

	getSubscriptionSettings() {
		return this.subscriptionSettings;
	}

	getCourses(): Course[] {
		return this.subscriptionSettings.courses;
	}

	setPackage(pk: SelfServicePackage) {
		this.package = pk;
	}

	getPackage(): SelfServicePackage {
		return this.package;
	}

	set _selfServiceStatus(status: SelfServiceSubscription) {
		this.selfServiceStatus;
	}

	getMatchingPackage(packagePK: number, setCourseAndSpecialization: boolean = false): SelfServicePackage {
		console.log('Matching Package:', packagePK);
		for (const course of this.subscriptionSettings.courses) {
			console.log('Checking Course:', course);
			for (const specialisation of course.specialisations) {
				console.log('Checking Specialisation:', specialisation);
				for (const pkg of specialisation.packages) {
					console.log('Checking Package:', pkg);
					if (pkg.pk === packagePK) {
						if (setCourseAndSpecialization) {
							console.log('Setting Course and Specialisation');
							this.setCourse(course);
							this.setSpecialization(specialisation);
						}
						return pkg;
					}
				}
			}
		}
		return null;
	}

	userHasPackage(): boolean {
		return this.selfServiceStatus.package;
	}

	isSelfServiceStartupLocationLoginAndIsFirstTimeSelfService(): boolean {
		return this.getSelfServiceStartupLocation() === SelfServiceStartupLocation.login && this.getSubscriptionSettings().is_first_self_service_package;
	}

	isSelfServiceStartupLocationLoginAndIsNotFirstTimeSelfService(): boolean {
		return this.getSelfServiceStartupLocation() === SelfServiceStartupLocation.login && !this.getSubscriptionSettings().is_first_self_service_package;
	}
}
