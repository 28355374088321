import { Injectable, NgZone } from '@angular/core';
import { additionalIOSAuthOptions, additionalWebAuthOptions, authProvider, environment, nativeZitadelAuthOptions, webZitadelAuthOptions } from '@environments/environment';
import { AuthConnect, AuthResult, ProviderOptions } from '@ionic-enterprise/auth';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { VaultService } from './vault.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	private initializing: Promise<void> | undefined;
	private isNative;
	private authProvider = authProvider;
	private authOptions: ProviderOptions;
	private authenticationChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public authenticationChange$: Observable<boolean>;
	private loginRetries: number = 0;

	constructor(
		platform: Platform,
		private ngZone: NgZone,
		private vaultService: VaultService
	) {
		// nativeZitadelAuthOptions.tokenStorageProvider = vaultService.vault;
		// webZitadelAuthOptions.tokenStorageProvider = vaultService.vault;
		// super(platform.is('hybrid')? nativeZitadelAuthOptions : webZitadelAuthOptions);

		this.isNative = platform.is('hybrid');
		this.authOptions = this.isNative ? nativeZitadelAuthOptions : webZitadelAuthOptions;
		this.initialize();
		this.authenticationChange$ = this.authenticationChange.asObservable();
		this.isUserAuthenticated().then((authenticated) => this.onAuthChange(authenticated));
	}

	private setup(): Promise<void> {
		return AuthConnect.setup({
			platform: this.isNative ? 'capacitor' : 'web',
			logLevel: environment.production ? 'DEBUG' : 'ERROR',
			ios: additionalIOSAuthOptions,
			web: additionalWebAuthOptions
		});
	}

	private initialize(): Promise<void> {
		if (!this.initializing) {
			this.initializing = new Promise((resolve) => {
				this.setup().then(() => resolve());
			});
		}
		return this.initializing;
	}

	public async login(): Promise<void> {
		await this.initialize();
		console.log('AuthProvider', this.authProvider);
		console.log('AuthOptions', this.authOptions);
		let authResult: AuthResult | null = null;
		try {
			authResult = await AuthConnect.login(this.authProvider, this.authOptions);
			console.log('AuthResult', authResult);
			await this.saveAuthResult(authResult);
			this.onAuthChange(await this.isUserAuthenticated());
		} catch (err) {
			if (this.loginRetries < 10) {
				console.log('LoginRetries', this.loginRetries);
				console.log('LoginError', err);
				this.loginRetries++;
				//await this.logout();
				await this.login();
			} else {
				// Create a new error with a custom message and include the original error details
				const errorMessage = err?.message ? `Login failed after 10 retries: ${err.message}` : 'Login failed after 10 retries';
				const newError = new Error(errorMessage);
				if (err?.stack) {
					newError.stack = err.stack;
				} else {
					// Convert the error object to a string of key-value pairs, handling nested objects
					const formatErrorObject = (obj, prefix = '') => {
						return Object.entries(obj)
							.map(([key, value]) => {
								if (typeof value === 'object' && value !== null) {
									return formatErrorObject(value, `${prefix}${key}.`);
								}
								return `${prefix}${key}: ${value}`;
							})
							.join('    ');
					};
					newError.stack = formatErrorObject(err || {});
				}
				throw newError;
			}
		}
	}

	public async logout(): Promise<void> {
		console.log('Logout');
		await this.initialize();
		const authResult = await this.getAuthResult();
		console.log('AuthResult in logout', authResult);
		if (authResult) {
			await AuthConnect.logout(this.authProvider, authResult);
			await this.saveAuthResult(null);
			localStorage.clear();
			this.onAuthChange(await this.isUserAuthenticated());
			console.log('Logout successful');
		}
	}

	private async onAuthChange(isAuthenticated: boolean): Promise<void> {
		this.ngZone.run(() => {
			this.authenticationChange.next(isAuthenticated);
		});
	}

	public async getAuthResult(): Promise<AuthResult | null> {
		let authResult = await this.vaultService.getSession();
		if (authResult && (await AuthConnect.isAccessTokenExpired(authResult))) {
			authResult = await this.refreshAuth(authResult);
		}
		return authResult;
	}

	public async getAccessToken(): Promise<string | undefined> {
		await this.initialize();
		const res = await this.getAuthResult();
		return res?.accessToken;
	}

	async isUserAuthenticatedNoRefreshAttempt(): Promise<boolean> {
		await this.initialize();
		let authResult = await this.vaultService.getSession();
		if (!authResult) {
			return false;
		} else {
			if (!(await AuthConnect.isAccessTokenAvailable(authResult)) || (await AuthConnect.isAccessTokenExpired(authResult))) {
				// No token available, not logged in or token expired

				return false;
			} else {
				return true;
			}
		}
	}

	async isUserAuthenticated(): Promise<boolean> {
		await this.initialize();
		return !!(await this.getAuthResult());
	}

	private async saveAuthResult(authResult: AuthResult | null): Promise<void> {
		if (authResult) {
			await this.vaultService.setSession(authResult);
		} else {
			await this.vaultService.clear();
		}
		this.onAuthChange(!!authResult);
	}

	private async refreshAuth(authResult: AuthResult): Promise<AuthResult | null> {
		let newAuthResult: AuthResult | null = null;
		if (await AuthConnect.isRefreshTokenAvailable(authResult)) {
			try {
				newAuthResult = await AuthConnect.refreshSession(this.authProvider, authResult);
			} catch (err) {
				null;
			}
			this.saveAuthResult(newAuthResult);
		}

		return newAuthResult;
	}

	public async isRefreshTokenAvailable(): Promise<boolean> {
		await this.initialize();
		let authResult = await this.vaultService.getSession();
		if (authResult) {
			return await AuthConnect.isRefreshTokenAvailable(authResult);
		} else {
			return false;
		}
	}

	/*async isAuthenticated(authResult: AuthResult) {
    await this.initialize();
    const isAccessTokenAvailable = await AuthConnect.isAccessTokenAvailable(authResult);
    const isAccessTokenExpired = await AuthConnect.isAccessTokenExpired(authResult);
    const isRefreshTokenAvailable = await AuthConnect.isRefreshTokenAvailable(authResult);
  
    if (isAccessTokenAvailable && !isAccessTokenExpired) {
      return true;
    }
  
    if (!navigator.onLine) {
      if (isRefreshTokenAvailable) return true;
      await this.vaultService.clear();
      return false;
    }
  
    try {
      const refreshedAuthResult = await AuthConnect.refreshSession(this.authProvider, authResult);
      await this.saveAuthResult(refreshedAuthResult);
      return true;
    } catch (err) {
      // Refresh failed, or no `refresh_token` available
      await this.vaultService.clear();
      return false;
    }
  }

  async isUserAuthenticated(): Promise<boolean> {
    await this.initialize();

    if (!(await AuthConnect.isAccessTokenAvailable(this.authResult))) {
      // No token available, not logged in

      return false;
    }
    if (await AuthConnect.isAccessTokenExpired(this.authResult)) {
      if (navigator.onLine) {
        // Token is expired, but we have a connection so we will attempt to refresh the token
        try {
          await AuthConnect.refreshSession(this.authProvider, this.authResult);

          return true;
        } catch (e) {
          // Refresh failed, clear the storage
          await this.vaultService.clear();

          return false;
        }
      } else {
        // Token is expired, but no connection. We will check for the presence
        // of a refresh token, and if it exists, we will assume the login is still valid

        return await AuthConnect.isRefreshTokenAvailable(this.authResult);
      }
    } else {
      // Access token is not expired, authentication is valid
      return true;
    }
  }*/
}
