import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { PackageService } from './package.service';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { ProgressDetails } from '@app/_models/progress-details';
import { GamificationService } from './gamification.service';
import { SingleTrainingContent } from '@app/_models/singleTrainingContent';
import { StepResourceType } from '@app/_models/step-resource-type';
import { StepDefinition } from '@app/_models/stepDefinition';
import { SingleTrainingService } from './single-training.service';
import { StepResult } from '@app/_models/step-result';
import {
	ExerciseProgressionStateName,
	PreviousStepInfoAudio,
	PreviousStepInfoGapText,
	PreviousStepInfoInfographic,
	PreviousStepInfoMatchingPair,
	PreviousStepInfoQuiz,
	PreviousStepInfoTextual,
	PreviousStepInfoVideo,
	ProgressionStateTraining
} from '@app/_models/progression-state';

@Injectable({
	providedIn: 'root'
})
export class ExerciseService {
	//API Prameters
	private microtrainingId: number;

	//Data
	private currentExerciseStepSubject: BehaviorSubject<StepDefinition>;
	public currentExerciseStepItem: Observable<StepDefinition>;

	private progressionStateSubject: BehaviorSubject<ProgressionStateTraining>;
	public progressionStateItem: Observable<ProgressionStateTraining>;

	public currentExerciseStepIndex: number;
	private trainingContent: SingleTrainingContent;
	private progressionState: ProgressionStateTraining;

	public useRealProgressionState: boolean = false;

	constructor(
		private apiService: ApiService,
		private gamificationService: GamificationService,
		private packageService: PackageService,
		private singleTrainingService: SingleTrainingService
	) {
		this.currentExerciseStepSubject = new BehaviorSubject<StepDefinition>(null);
		this.currentExerciseStepItem = this.currentExerciseStepSubject.asObservable();
		this.progressionStateSubject = new BehaviorSubject<ProgressionStateTraining>(null);
		this.progressionStateItem = this.progressionStateSubject.asObservable();

		this.progressionStateSubject.subscribe((progressionState) => {
			this.progressionState = progressionState;
		});
	}

	async loadSingleTrainingContent(microtrainingId: number, countMinutes: boolean, useRealProgressionState = true): Promise<SingleTrainingContent> {
		this.microtrainingId = microtrainingId;
		this.useRealProgressionState = useRealProgressionState;
		let params = new HttpParams();
		params = params.set('microtraining_id', microtrainingId.toString());
		params = params.set('package_id', this.packageService.getCurrentPackage().pk);
		params = params.set('week', this.singleTrainingService.getCurrentWeek().toString());
		params = params.set('count_minutes', countMinutes);
		const response = await this.apiService
			.get<SingleTrainingContent>('/microtrainings/singletraining/content', params)
			.pipe(
				map((singleTrainingContent) => {
					singleTrainingContent.exercise.exerciseSteps = singleTrainingContent.exercise.exerciseSteps.sort((a, b) => a.order_number - b.order_number);
					this.trainingContent = singleTrainingContent;
					return singleTrainingContent;
				})
			)
			.toPromise();

		if (!response) {
			return Promise.reject();
		}
		console.log(`Single Training Content First Load`, this.trainingContent);

		this.currentExerciseStepIndex = 0;
		// useRealProgressionState is always true for normal trainings, therefore ProgressionState ist initialized with loaded data
		this.progressionStateSubject.next(this.trainingContent.progression_state);
		if (this.progressionState.order_number != null) {
			this.currentExerciseStepIndex = this.trainingContent.progression_state.order_number;
			// if order_number is on last exercise step, the exercise is done
			if (this.progressionState.order_number >= this.trainingContent.exercise.exerciseSteps.length) {
				this.progressionState.state = ExerciseProgressionStateName.ExerciseCompletlyDone;
			}
		}

		this.currentExerciseStepSubject.next(this.trainingContent.exercise.exerciseSteps[this.currentExerciseStepIndex]);
		console.group();
		console.log(`Progression State First Load`, this.progressionState);
		console.log(`Current Exercise Step First Load`, this.currentExerciseStepSubject.value);
		console.log(`Single Training Content First Load`, this.trainingContent);
		console.groupEnd();
		return Promise.resolve(response);
	}

	async loadREDOSingleTrainingContent(microtraining_uuid: string, countMinutes: boolean, useRealProgressionState = true): Promise<SingleTrainingContent> {
		this.useRealProgressionState = useRealProgressionState;
		let params = new HttpParams();
		params = params.set('microtraining_uuid', microtraining_uuid);
		params = params.set('package_id', this.packageService.getCurrentPackage().pk);
		params = params.set('week', this.singleTrainingService.getCurrentWeek().toString());
		params = params.set('count_minutes', countMinutes);
		const response = await this.apiService
			.get<SingleTrainingContent>('/history/redo/microtraining', params)
			.pipe(
				map((singleTrainingContent) => {
					singleTrainingContent.exercise.exerciseSteps = singleTrainingContent.exercise.exerciseSteps.sort((a, b) => a.order_number - b.order_number);
					this.trainingContent = singleTrainingContent;
					return singleTrainingContent;
				})
			)
			.toPromise();

		if (!response) {
			return Promise.reject();
		}
		console.log(`Single Training Content First Load`, this.trainingContent);

		this.currentExerciseStepIndex = 0;

		// useRealProgressionState is always false for redo trainings, therefore no ProgressionState
		this.progressionStateSubject.next({ order_number: null, state: null, previous_steps_info: [] });

		this.currentExerciseStepSubject.next(this.trainingContent.exercise.exerciseSteps[this.currentExerciseStepIndex]);
		console.group();
		console.log(`Progression State First Load`, this.progressionState);
		console.log(`Current Exercise Step First Load`, this.currentExerciseStepSubject.value);
		console.log(`Single Training Content First Load`, this.trainingContent);
		console.groupEnd();
		return Promise.resolve(response);
	}

	async saveExerciseStepCompleted(exerciseStepOrderNumber: number, result: StepResult): Promise<any> {
		if (!this.useRealProgressionState) {
			this.mockExerciseStepCompleted(exerciseStepOrderNumber, result);
			return;
		}
		const postData = {
			package: this.packageService.getCurrentPackage().pk,
			week: this.packageService.getCurrentPackage().week.toString(),
			microtraining: this.microtrainingId,
			progression_state_name: 'exercise_step_done',
			progression_state_value: exerciseStepOrderNumber,
			result
		};

		const response = await this.apiService.post<{ progression_state: ProgressionStateTraining }>('/microtrainings/singletraining/state_update', postData).toPromise();
		if (!response) {
			return;
		}

		if (this.useRealProgressionState) {
			this.progressionStateSubject.next(response.progression_state);
			console.log(`Progression State Updated`, response.progression_state);
		}
		return;
	}

	async deleteProgressState(): Promise<any> {
		if (!this.useRealProgressionState) {
			return;
		}
		const deleteData = {
			package: this.packageService.getCurrentPackage().pk,
			microtraining: this.microtrainingId
		};

		const response = await this.apiService.delete('/microtrainings/singletraining/state_update', deleteData).toPromise();
	}

	mockExerciseStepCompleted(exerciseStepOrderNumber: number, result: StepResult) {
		const steptype = this.trainingContent.exercise.exerciseSteps[exerciseStepOrderNumber - 1].resourcetype;
		let newProgressionState: ProgressionStateTraining = structuredClone(this.progressionState);

		switch (steptype) {
			case StepResourceType.StepTextual:
			case StepResourceType.StepInfographic:
			case StepResourceType.StepVideo:
			case StepResourceType.StepAudio:
				newProgressionState.previous_steps_info.push({
					order_number: exerciseStepOrderNumber,
					step_type: steptype,
					result: []
				} as PreviousStepInfoTextual | PreviousStepInfoInfographic | PreviousStepInfoVideo | PreviousStepInfoAudio);
				break;
			case StepResourceType.StepQuiz:
				if ('quiz' in result) {
					const quizResult = result as { quiz: { answer_id: number } };
					const currentStep = this.trainingContent.exercise.exerciseSteps[exerciseStepOrderNumber - 1];
					if (currentStep.resourcetype === StepResourceType.StepQuiz) {
						const correctChoice = currentStep.answer_choices.find((choice) => choice.correct);
						newProgressionState.previous_steps_info.push({
							order_number: exerciseStepOrderNumber,
							step_type: StepResourceType.StepQuiz,
							result: [
								{
									step_quiz: exerciseStepOrderNumber,
									answer_choice: quizResult.quiz.answer_id,
									correct_choice: correctChoice?.pk === quizResult.quiz.answer_id
								}
							]
						} as PreviousStepInfoQuiz);
					}
				}
				break;
			case StepResourceType.StepMatchingPairs:
				if ('matching_pairs' in result) {
					const matchingPairsResult = result as { matching_pairs: { pair_id: number; tries_till_right_answer: number }[] };
					const currentStep = this.trainingContent.exercise.exerciseSteps[exerciseStepOrderNumber - 1];
					if (currentStep.resourcetype === StepResourceType.StepMatchingPairs) {
						newProgressionState.previous_steps_info.push({
							order_number: exerciseStepOrderNumber,
							step_type: StepResourceType.StepMatchingPairs,
							result: matchingPairsResult.matching_pairs
						} as PreviousStepInfoMatchingPair);
					}
				}
				break;
			case StepResourceType.StepGapText:
				const gapTextResult = result as { gap_text: { gap_id: number; tries_till_right_answer: number }[] };
				const currentStep = this.trainingContent.exercise.exerciseSteps[exerciseStepOrderNumber - 1];
				if (currentStep.resourcetype === StepResourceType.StepGapText) {
					newProgressionState.previous_steps_info.push({
						order_number: exerciseStepOrderNumber,
						step_type: StepResourceType.StepGapText,
						result: { gap_text: gapTextResult.gap_text }
					} as PreviousStepInfoGapText);
				}
				break;
			default:
				break;
		}

		newProgressionState.order_number = exerciseStepOrderNumber;
		this.progressionStateSubject.next(newProgressionState);
	}

	saveSingleTrainingCompleted(): Promise<any> {
		const postData = {
			package: this.packageService.getCurrentPackage().pk,
			week: this.singleTrainingService.getCurrentWeek().toString(),
			microtraining: this.microtrainingId
		};

		this.gamificationService.resetProgressDetails();

		return this.apiService
			.post<ProgressDetails>(`/microtrainings/singletraining/completed`, postData)
			.pipe(
				map((progressDetails) => {
					this.gamificationService.progressDetails = progressDetails;
				})
			)
			.toPromise();
	}

	async saveExerciseVideoViewed() {
		if (!this.useRealProgressionState) {
			return;
		}
		const postData = {
			package: this.packageService.getCurrentPackage().pk,
			week: this.packageService.getCurrentPackage().week.toString(),
			microtraining: this.microtrainingId,
			progression_state_name: ExerciseProgressionStateName.VideoDone,
			progression_state_value: true,
			result: {}
		};

		this.apiService.post<{ progression_state: ProgressionStateTraining }>(`/microtrainings/singletraining/state_update`, postData).toPromise();
	}

	async saveExerciseGoalSelectionDone() {
		const postData = {
			package: this.packageService.getCurrentPackage().pk,
			week: this.packageService.getCurrentPackage().week.toString(),
			microtraining: this.microtrainingId,
			progression_state_name: ExerciseProgressionStateName.GoalSelectionDone,
			progression_state_value: true,
			result: {}
		};
		this.apiService.post<{ progression_state: ProgressionStateTraining }>(`/microtrainings/singletraining/state_update`, postData).toPromise();
	}

	updateProgressionState() {
		if (this.useRealProgressionState) {
			this.progressionStateSubject.next(this.trainingContent.progression_state);
			console.log(`Progression State Updated`, this.trainingContent.progression_state);
		} else {
			this.progressionStateSubject.next({ order_number: null, state: null, previous_steps_info: [] });
		}
	}

	setUseProgressionState(useProgressionState: boolean) {
		this.useRealProgressionState = useProgressionState;
		console.log(`Use Progression State: ${this.useRealProgressionState}`);
		this.updateProgressionState();
	}

	setCurrentExerciseStep(index: number) {
		this.currentExerciseStepSubject.next(this.trainingContent.exercise.exerciseSteps[index]);
	}

	incrementCurrentExerciseStep() {
		this.currentExerciseStepSubject.next(this.trainingContent.exercise.exerciseSteps[++this.currentExerciseStepIndex]);
	}

	decrementCurrentExerciseStep() {
		this.currentExerciseStepSubject.next(this.trainingContent.exercise.exerciseSteps[--this.currentExerciseStepIndex]);
	}

	getSingleTrainingContent(): SingleTrainingContent {
		return this.trainingContent;
	}

	getExerciseLength(): number {
		return this.trainingContent.exercise.exerciseSteps.length;
	}

	setCurrentExerciseStepIndex(index: number) {
		this.currentExerciseStepIndex = index;
	}

	getCurrentExerciseStepIndex() {
		return this.currentExerciseStepIndex;
	}

	getProgressionState(): ProgressionStateTraining {
		return this.progressionStateSubject.value;
	}

	getExerciseProgressionStateName(): ExerciseProgressionStateName {
		return this.progressionState.state || null;
	}
}
