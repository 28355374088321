class FileNotFoundError extends Error {
	constructor(message: string = 'file not found') {
		super(message);
		this.name = 'FileNotFound';
	}
}

class StorageServiceError extends Error {
	constructor(message: string = 'storage service error') {
		super(message);
		this.name = 'StorageServiceError';
	}
}

class FeedBackProfileIsUndefinedError extends Error {
	constructor(message: string = 'feedback profile undefined') {
		super(message);
		this.name = 'FeedBackProfileError';
	}
}

enum GivenFromNotFoundEnum {
	FeedbackProfile,
	AvailableFeedbacks
}

interface IGivenFromNotFound extends Error {
	type: GivenFromNotFoundEnum;
}

class GivenFromNotFoundError extends Error implements IGivenFromNotFound {
	type: GivenFromNotFoundEnum;
	message: string;
	constructor(type: GivenFromNotFoundEnum, message: string = 'GivenFrom not found in') {
		super(message + ' ' + type);
		this.name = 'GivenFromNotFound';
	}
}

class ThemingError extends Error {
	constructor(message: string = 'Theming error') {
		super(message);
		this.name = 'ThemingError';
	}
}

export { FileNotFoundError, FeedBackProfileIsUndefinedError, GivenFromNotFoundError, GivenFromNotFoundEnum, StorageServiceError, ThemingError };
