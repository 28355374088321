import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ProgressDetails } from '@app/_models/progress-details';
import { map } from 'rxjs';
import { PlayerProgressInfo } from '@app/_models/player-progress-info';
import { GamificationService } from './gamification.service';

@Injectable({
	providedIn: 'root'
})
export class TutorialService {
	progressDetails: ProgressDetails;

	achievementTutorialStartupLocation: AchievementTutorialStartupLocation = AchievementTutorialStartupLocation.home;
	showSpacemapTutorial: boolean = false;

	constructor(
		private apiService: ApiService,
		private gamificationService: GamificationService
		//private rmiService: RewardedMicroInteractionService,
		//private translationService: TranslationService
	) {}

	//SAVING

	saveTourGuideViewed(): Promise<any> {
		return this.apiService.post('/gamification/tour_guide_viewed').toPromise();
	}

	saveAchievementTutorialViewed(): Promise<any> {
		return this.apiService.post('/gamification/achievement_tutorial_viewed').toPromise();
	}
	saveJourneySpacemapTutorialViewed(): Promise<any> {
		return this.apiService.post('/gamification/spacemap_journey_tutorial_viewed').toPromise();
	}
	saveHistoryTutorialViewed(): Promise<any> {
		return this.apiService.post('/gamification/history_tutorial_viewed').toPromise();
	}

	saveMultipackageHubTutorialViewed(): Promise<any> {
		return this.apiService.post('/gamification/multipackage_hub_tutorial_viewed').toPromise();
	}

	saveStreakIncreaseViewed(): Promise<any> {
		return this.apiService
			.post<ProgressDetails>('/gamification/streak_increase_viewed')
			.pipe(map((playerProgress) => (this.progressDetails = playerProgress)))
			.toPromise();
	}

	saveProfileTutorialViewed(): Promise<void> {
		return this.apiService
			.post<PlayerProgressInfo>('/profile/tutorial', {})
			.pipe(map((playerProgression) => this.gamificationService.setPlayerProgressInfo(playerProgression)))
			.toPromise();
	}

	saveStreakExplainationViewed() {
		this.apiService.post('/gamification/streak_explanation_viewed').toPromise();
	}

	//SPACEMAP

	getShowSpacemapTutorial(): boolean {
		return this.showSpacemapTutorial;
	}

	setShowJourneySpacemapTutorial() {
		this.showSpacemapTutorial = true;
	}

	resetShowJourneySpacemapTutorial() {
		this.showSpacemapTutorial = false;
	}

	//ACHIEVEMENT TUTORIAL

	getAchievementTutorialStartupLocation(): AchievementTutorialStartupLocation {
		return this.achievementTutorialStartupLocation;
	}

	setAchievementTutorialStartupLocation(location: AchievementTutorialStartupLocation) {
		this.achievementTutorialStartupLocation = location;
	}

	//JOURNEY SPACEMAP

	//HISTORY / PROGRESSION

	//PROFILE STATS + MPH

	//PROFILE INSIGHTS

	//STREAK INCREASE

	//STREAK EXPLANATION
}

export enum AchievementTutorialStartupLocation {
	home,
	journey
}
